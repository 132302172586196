<template>
  <b-modal
      modal-class="modal_basic add_tags_modal sm-modal"
      id="addEditTagModal"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header
  >
    <div class="w-full h-full">
      <div
          class="w-full h-full flex flex-col items-center justify-between pt-2 gap-6"
      >
        <div class="flex flex-col items-center justify-center">
          <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M29.0494 18.6474L18.6582 29.0386C18.389 29.3081 18.0694 29.5219 17.7175 29.6677C17.3656 29.8136 16.9885 29.8887 16.6075 29.8887C16.2266 29.8887 15.8495 29.8136 15.4976 29.6677C15.1457 29.5219 14.826 29.3081 14.5568 29.0386L3.1261 17.6211C2.47407 16.9699 2.1077 16.0861 2.1077 15.1645V5.5835C2.1077 3.66587 3.66224 2.11133 5.57986 2.11133H15.1687C16.09 2.11133 16.9734 2.47742 17.6246 3.12902L29.0494 14.5605C29.5893 15.1035 29.8923 15.8382 29.8923 16.6039C29.8923 17.3697 29.5893 18.1043 29.0494 18.6474Z"
                stroke="#2560D7"
                stroke-width="2.25691"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.35381 9.35742H9.36831"
                stroke="#2560D7"
                stroke-width="5.20825"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
          <p
              class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5"
          >
            {{ Object.keys(EditObject).length > 1 ? "Edit" : "Add" }} Tag
          </p>

          <p class="pt-1 text !text-[14px] text-center">
            {{
              Object.keys(EditObject).length > 1
                  ? "Please edit details to update tag."
                  : "Please enter details to add tag."
            }}
          </p>
        </div>

        <div class="flex flex-col justify-center item-center w-full gap-3">
          <FloatingLabelInput
              id="tag"
              @enter="EditObject.id ? editTags() : (EditObject.type ? mergeTagsCall() : storeTagName())"
              :errorType="
              validations.tag_name || validations.tag_name_length
                ? 'danger'
                : ''
            "
              class="mb-3 w-full"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label="Tag"
              placeholder="Enter tag name"
              v-model="TagName"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                  v-if="validations.tag_name"
                  :message="messages.tag_name"
              ></InputFieldMessage>
              <InputFieldMessage
                  v-else-if="validations.tag_name_length"
                  :message="messages.tag_name_length"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>
        </div>
        <div class="mt-2 flex gap-x-3 justify-between items-center">
          <Button
              id="close-button"
              type="button"
              class="!border-[1.5px] !border-[#3C4549] !py-[1.142rem] !text-[14px] !rounded-[0.571rem] hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="close()"
          >
            <template v-slot:label>Close</template>
          </Button>

          <Button
              id="store-button"
              type="button"
              class="text-white bg-[#2560D7] !py-[1.142rem] !text-[14px] !rounded-[0.571rem] hover:bg-blue-700"
              buttonClass="btn-lg"
              @click.prevent="EditObject.id ? editTags() : (EditObject.type ? mergeTagsCall() : storeTagName())"
              :disabledProp="addTagLoader"
          >
            <template v-slot:label>{{
                Object.keys(EditObject).length > 1 ? "Edit" : "Add"
              }}
            </template>
            <template v-if="addTagLoader" v-slot:loader>
              <Loader></Loader>
            </template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    EditObject: {
      type: Object,
      required: false,
    },
  },
  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    FloatingLabelInput: () => import("@/ui/ui-kit/v2/FloatingLabelInput.vue"),
    InputFieldMessage: () => import("@/ui/ui-kit/v2/InputFieldMessage.vue"),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
  },
  data() {
    return {
      add_tag_name: null,
      validations: {
        tag_name: false,
        tag_name_length: false,
      },
      messages: {
        tag_name: "Tag name is required.",
        tag_name_length: "The tag should not be greater than 35 characters.",
      },
      addTagLoader: false,
    };
  },

  methods: {
    ...mapActions(["storeTag", "fetchTags", "mergeTags",]),

    mergeTagsCall() {
      this.mergeTags();
      this.close();
    },

    close() {
      this.add_tag_name = "";
      this.validations = {
        tag_name: false,
        tag_name_length: false,
      };
      this.$bvModal.hide("addEditTagModal");

    },

    async editTags() {
      let id = this.EditObject._id
      let value = this.add_tag_name
      if (value && (value.trim()).length > 0) {
        this.addTagLoader = true
        await this.storeTag({id: id, name: value, type: 'edit'})
        this.add_tag_name = "";
        this.addTagLoader = false
        $('.marge_tooltip').removeClass('animate_it')
        this.$bvModal.hide("addEditTagModal");
      } else {
        this.alertMessage('Please enter tag name.', 'error')
      }
    },

    async storeTagName() {
      this.validations.tag_name = this.requiredCheck(this.add_tag_name);
      let result = Object.keys(this.validations).every(
          (k) => this.validations[k] === false
      );
      if (result) {
        this.addTagLoader = true;
        await this.storeTag({name: this.add_tag_name}).then((res) => {
          if (res) {
            this.$bvModal.hide("addEditTagModal");
            this.add_tag_name = "";
            this.$store.dispatch("toastNotification", {
              message: "Tag name added successfully.",
            });
            this.fetchTags();
          }
          this.addTagLoader = false;
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      'getTagSelection',
      'getTag'
    ]),
    TagName: {
      // getter
      get: function () {
        return this.EditObject.hasOwnProperty('_id') ? this.EditObject.tag : this.add_tag_name;
      },
      // setter
      set: function (newValue) {
        this.EditObject.hasOwnProperty('type') ? this.getTag.merge = newValue : this.add_tag_name = newValue;
      }
    }

  },
  watch: {
    'EditObject': {
      handler(newValue, oldValue) {
        if (newValue) {
          this.add_tag_name = newValue._id ? newValue.tag : '';
        }
      },
      Deep: true,
    },
    add_tag_name(value) {
      if (value && value.length > 0) {
        this.validations.tag_name = false;
        this.validations.tag_name_length = !this.maxLength(value, 35);
      }
    },
  },
};
</script>
<style lang="less">
.add_tags_modal {
  .modal-dialog {
    max-width: 28.71rem !important;
  }

  .modal-body {
    padding: 40px 40px 58px 40px !important;
  }
}
</style>

