<template>
  <div class="setting_form h-full">
    <AddTagModalV2 :EditObject="EditObject" ></AddTagModalV2>
    <DeleteTagModalV2 :DelObj="TagToDel"></DeleteTagModalV2>
    <div class="w-full h-full bg-[#F7F7FC]">
      <div class="grid_box_shadow bg-white w-full h-full rounded-[8px]">
        <div class="flex flex-col justify-between h-full">
          <div class="flex flex-col flex-grow h-full border-[#ECEEF5]">
            <!-- Header -->
            <div class="flex px-3 py-[1rem] h-[4.188rem] justify-between items-center border-b border-[#ECEEF5]">
              <div class="w-full flex gap-x-2">
                <Button
                  id="create-new"
                  type="button"
                  class="bg-blue-600 hover:bg-blue-700"
                  buttonClass="btn-md"
                  @click.prevent="addTagModal"
                >
                  <template v-slot:label>Add Tag </template>
                </Button>
              </div>
              <div class="w-full flex justify-center items-center">
                <p class="text font-bold text-center !text-[1.125rem] pr-[0.2rem] !text-[#3C4549]">Tags</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z"
                    fill="#3C4549"
                  />
                </svg>
              </div>
              <div class="w-full flex items-center justify-end space-x-2">
                <template v-if="getTagSelection.length > 1">
                <div class="w-7 h-7 flex items-center justify-center">
                      <svg
                        class="cursor-pointer"
                        v-tooltip="'Edit Tag'"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click.prevent="MergeModal($event)"
                      >
                        <path
                          d="M11.6533 1.15998C12.0759 0.737402 12.6491 0.5 13.2467 0.5C13.8443 0.5 14.4174 0.737402 14.84 1.15998C15.2626 1.58256 15.5 2.1557 15.5 2.75332C15.5 3.35093 15.2626 3.92407 14.84 4.34665L4.74889 14.4378L0.5 15.5L1.56222 11.2511L11.6533 1.15998Z"
                          stroke="#3C4549"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div
                      class="rounded-full bg-pink-100 w-7 h-7 flex items-center justify-center"
                    >
                      <svg
                        ref="multipleDelete"
                        class="cursor-pointer"
                        v-tooltip="'Delete Tag Multiple'"
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click.prevent="DeleteModal($event)"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.70143 1.91636C5.88803 1.72976 6.14112 1.62493 6.40501 1.62493H9.59505C9.85894 1.62493 10.112 1.72976 10.2986 1.91636C10.4852 2.10296 10.5901 2.35605 10.5901 2.61994V3.61398H5.41V2.61994C5.41 2.35605 5.51483 2.10296 5.70143 1.91636ZM4.77554 4.81398C4.78694 4.81463 4.79843 4.81496 4.81 4.81496C4.82157 4.81496 4.83306 4.81463 4.84446 4.81398H11.1556C11.167 4.81463 11.1785 4.81496 11.1901 4.81496C11.2016 4.81496 11.2131 4.81463 11.2245 4.81398H12.9826V15.3801C12.9826 15.644 12.8778 15.897 12.6912 16.0836C12.5045 16.2703 12.2515 16.3751 11.9876 16.3751H4.01249C3.7486 16.3751 3.49551 16.2703 3.30891 16.0836C3.12231 15.897 3.01748 15.644 3.01748 15.3801V4.81398H4.77554ZM4.21 3.61398V2.61994C4.21 2.03779 4.44126 1.47948 4.8529 1.06783C5.26455 0.656187 5.82286 0.424927 6.40501 0.424927H9.59505C10.1772 0.424927 10.7355 0.656187 11.1472 1.06783C11.5588 1.47948 11.7901 2.03779 11.7901 2.61994V3.61398H15.1776C15.5089 3.61398 15.7776 3.88261 15.7776 4.21398C15.7776 4.54536 15.5089 4.81398 15.1776 4.81398H14.1826V15.3801C14.1826 15.9622 13.9513 16.5205 13.5397 16.9322C13.128 17.3438 12.5697 17.5751 11.9876 17.5751H4.01249C3.43034 17.5751 2.87203 17.3438 2.46038 16.9322C2.04874 16.5205 1.81748 15.9622 1.81748 15.3801V4.81398H0.822412C0.491041 4.81398 0.222412 4.54536 0.222412 4.21398C0.222412 3.88261 0.491041 3.61398 0.822412 3.61398H2.41743H4.21ZM6.40487 7.60191C6.73624 7.60191 7.00487 7.87054 7.00487 8.20191V12.987C7.00487 13.3183 6.73624 13.587 6.40487 13.587C6.0735 13.587 5.80487 13.3183 5.80487 12.987V8.20191C5.80487 7.87054 6.0735 7.60191 6.40487 7.60191ZM10.195 8.20191C10.195 7.87054 9.92637 7.60191 9.595 7.60191C9.26363 7.60191 8.995 7.87054 8.995 8.20191V12.987C8.995 13.3183 9.26363 13.587 9.595 13.587C9.92637 13.587 10.195 13.3183 10.195 12.987V8.20191Z"
                          fill="#97304A"
                        />
                      </svg>
                    </div>
                  </template>
              </div>
            </div>
            <!-- Table-->
            <div
              class="flex flex-row px-3 justify-center py-8 gap-x-3 gap-y-3 items-start flex-wrap border-[#ECEEF5] min-h-[300px]"
              style="align-content: start"
            >
              <template v-if="fetchLoader">
                <clip-loader :size="'26px'" :color="'#1d4ed8'"></clip-loader>
              </template>
              <template v-else-if="getTagList.length == 0">
                <p class="no-rows text-[#3C4549] text-[0.875rem] font-poppins">
                  <i class="fab fa-bandcamp" aria-hidden="true"></i>
                  You have not created any tags yet.
                </p>
              </template>
              <template v-else>
                <div
                  class="cursor-pointer inline-flex gap-3 items-center rounded-full border border-solid border-gray-400 p-[0.38rem]"
                  v-for="(tags, index) in getTagList"
                  :class="{
                    'bg-gray-300': getTagSelection.indexOf(tags._id) >= 0,
                  }"
                  v-tooltip.top-center="tags.tag"
                >
                  <div
                    class="flex flex-col items-center justify-center gap-10 w-7 h-7 rounded-full bg-gray-300"
                    @click.prevent="selectTagForAction(tags._id)"
                  >
                    <p class="text-gray-700 font-poppins text-xs font-normal">
                      {{ index + 1 }}
                    </p>
                  </div>
                  <p
                    class="text-gray-700 font-poppins text-sm font-normal"
                    @click.prevent="selectTagForAction(tags._id)"
                  >
                    {{ tags.tag }}
                  </p>
                  <div class="inline-flex items-center justify-center gap-1">
                    <div class="w-7 h-7 flex items-center justify-center">
                      <svg
                        class="cursor-pointer"
                        v-tooltip="'Edit Tag'"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click.prevent="EditModal($event, tags)"
                      >
                        <path
                          d="M11.6533 1.15998C12.0759 0.737402 12.6491 0.5 13.2467 0.5C13.8443 0.5 14.4174 0.737402 14.84 1.15998C15.2626 1.58256 15.5 2.1557 15.5 2.75332C15.5 3.35093 15.2626 3.92407 14.84 4.34665L4.74889 14.4378L0.5 15.5L1.56222 11.2511L11.6533 1.15998Z"
                          stroke="#3C4549"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div
                      class="rounded-full bg-pink-100 w-7 h-7 flex items-center justify-center"
                    >
                      <svg
                      :id="`DeleteSingle-${tags._id}`"
                        ref="singleDelete"
                        class="cursor-pointer"
                        v-tooltip="'Delete Tag'"
                        width="16"
                        height="18"
                        viewBox="0 0 16 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        @click.prevent="DeleteModal($event, [tags._id])"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.70143 1.91636C5.88803 1.72976 6.14112 1.62493 6.40501 1.62493H9.59505C9.85894 1.62493 10.112 1.72976 10.2986 1.91636C10.4852 2.10296 10.5901 2.35605 10.5901 2.61994V3.61398H5.41V2.61994C5.41 2.35605 5.51483 2.10296 5.70143 1.91636ZM4.77554 4.81398C4.78694 4.81463 4.79843 4.81496 4.81 4.81496C4.82157 4.81496 4.83306 4.81463 4.84446 4.81398H11.1556C11.167 4.81463 11.1785 4.81496 11.1901 4.81496C11.2016 4.81496 11.2131 4.81463 11.2245 4.81398H12.9826V15.3801C12.9826 15.644 12.8778 15.897 12.6912 16.0836C12.5045 16.2703 12.2515 16.3751 11.9876 16.3751H4.01249C3.7486 16.3751 3.49551 16.2703 3.30891 16.0836C3.12231 15.897 3.01748 15.644 3.01748 15.3801V4.81398H4.77554ZM4.21 3.61398V2.61994C4.21 2.03779 4.44126 1.47948 4.8529 1.06783C5.26455 0.656187 5.82286 0.424927 6.40501 0.424927H9.59505C10.1772 0.424927 10.7355 0.656187 11.1472 1.06783C11.5588 1.47948 11.7901 2.03779 11.7901 2.61994V3.61398H15.1776C15.5089 3.61398 15.7776 3.88261 15.7776 4.21398C15.7776 4.54536 15.5089 4.81398 15.1776 4.81398H14.1826V15.3801C14.1826 15.9622 13.9513 16.5205 13.5397 16.9322C13.128 17.3438 12.5697 17.5751 11.9876 17.5751H4.01249C3.43034 17.5751 2.87203 17.3438 2.46038 16.9322C2.04874 16.5205 1.81748 15.9622 1.81748 15.3801V4.81398H0.822412C0.491041 4.81398 0.222412 4.54536 0.222412 4.21398C0.222412 3.88261 0.491041 3.61398 0.822412 3.61398H2.41743H4.21ZM6.40487 7.60191C6.73624 7.60191 7.00487 7.87054 7.00487 8.20191V12.987C7.00487 13.3183 6.73624 13.587 6.40487 13.587C6.0735 13.587 5.80487 13.3183 5.80487 12.987V8.20191C5.80487 7.87054 6.0735 7.60191 6.40487 7.60191ZM10.195 8.20191C10.195 7.87054 9.92637 7.60191 9.595 7.60191C9.26363 7.60191 8.995 7.87054 8.995 8.20191V12.987C8.995 13.3183 9.26363 13.587 9.595 13.587C9.92637 13.587 10.195 13.3183 10.195 12.987V8.20191Z"
                          fill="#97304A"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { tagTypes } from "@/state/modules/mutation-types";
import { DARK_PURPLE_LOADER_COLOR } from "@/common/constants";
import DeleteTagModalV2 from "./DeleteTagModalV2.vue";
import AddTagModalV2 from "./AddTagModalV2.vue";

export default {
  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    DeleteTagModalV2: DeleteTagModalV2,
    AddTagModalV2: AddTagModalV2,
  },
  data() {
    return {
      TagToDel: null,
      size: "17px",
      EditObject: {},
      color: DARK_PURPLE_LOADER_COLOR,
      add_tag_name: null,
      validations: {
        tag_name: false,
        tag_name_length: false,
      },
      messages: {
        tag_name: "Tag name is required.",
        tag_name_length: "The tag should not be greater than 35 characters.",
      },
      addTagLoader: false,
      fetchLoader: false,
    };
  },

  async created() {
    this.getSiteDetails;
    this.fetchLoader = true;
    await this.fetchTags();
    this.fetchLoader = false;
  },
  computed: {
    ...mapGetters([
      "getTagList",
      "getTagSelection",
      "getTag",
      "getGuidedTour",
      "getTeam",
      "getBrands",
      "getUserLoader",
      "getDomains",
      "getTeamMember",
    ]),
  },
  methods: {
    ...mapMutations([tagTypes.SET_TAG_SELECTION]),
    ...mapActions([
      "storeTag",
      "deleteTags",
      "fetchTags",
      "mergeTags",
      "setAllBrandsFetchStatus"
    ]),
    deleteTagsMultiple(){
      this.deleteTags(this.getTagSelection);
    },
    EditModal(event, EditObj = null) {
      this.EditObject = EditObj;
      this.$bvModal.show("addEditTagModal");
    },
    MergeModal(event) {
      this.EditObject = { type:'mergeModal' };
      this.$bvModal.show("addEditTagModal");
    },
    DeleteModal(event, DeleteObj = null) {
      /* incase of multiple division the second argument will ne null */
      if(event.currentTarget.id.includes('DeleteSingle')) {
        this.TagToDel = [...DeleteObj];
      } else {
        this.TagToDel = this.getTagSelection;
        // multiple delete case
      }
      setTimeout(() => {
        this.$bvModal.show("delete-tags-modal");
      }, 150);
    },
    toggleTooltip(e) {
      this.getTag.edit = "";
      $(e.currentTarget.nextElementSibling).toggleClass("animate_it");
    },
    selectTagForAction(id) {
      let selectedTags = this.getTagSelection;
      let index = selectedTags.indexOf(id);
      if (index >= 0) {
        selectedTags.splice(index, 1);
      } else {
        selectedTags = selectedTags.concat(id);
      }
      this[tagTypes.SET_TAG_SELECTION](selectedTags);
    },

    async editTags() {
      let id = this.getTagSelection[0];
      let value = this.getTag.edit;
      if (value && value.trim().length > 0) {
        this.addTagLoader = true;
        await this.storeTag({ id: id, name: value, type: "edit" });
        this.addTagLoader = false;
        $(".marge_tooltip").removeClass("animate_it");
      } else {
        this.alertMessage("Please enter tag name.", "error");
      }
    },

    addTagModal() {
      this.$bvModal.show("addEditTagModal");
    },
  },
};
</script>

<style lang="less">
.sm-modal {
  .modal-dialog {
    max-width: 400px !important;
  }
}
</style>
